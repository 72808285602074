import { Grid } from '@mui/material/index';
import PropTypes from 'prop-types';

// Our Components
import Loader from 'components/Loader/index';
import { PrimaryButton } from 'components/Button/Button';

function FormButton({
	isMutationLoading,
	isFormReady,
	submitLiabilityData,
	enableSupportSpacing
}) {
	return (
		<>
			{enableSupportSpacing && (
				<Grid
					item
					xs={0}
					sm={0}
					md={5}
					lg={4}
					sx={{
						display: {
							xs: 'none',
							sm: 'none',
							md: 'flex'
						},
						marginLeft: {
							md: 1
						},
						marginRight: {
							md: 1
						}
					}}
				/>
			)}
			<Grid
				item
				xs={12}
				sm={10}
				lg={8}
				sx={{
					paddingTop: 2,
					paddingBottom: 2,
					marginLeft: 2,
					marginRight: 2
				}}
			>
				{isMutationLoading && <Loader />}
				{!isMutationLoading && (
					<PrimaryButton
						sx={{
							width: {
								xs: '95%',
								sm: '100%',
								md: 'fit-content'
							},
							marginTop: 1
						}}
						isDisabled={!isFormReady}
						onClick={submitLiabilityData}
					>
						Save
					</PrimaryButton>
				)}
			</Grid>
		</>
	);
}

FormButton.propTypes = {
	isMutationLoading: PropTypes.bool.isRequired,
	isFormReady: PropTypes.bool.isRequired,
	submitLiabilityData: PropTypes.func.isRequired,
	enableSupportSpacing: PropTypes.bool
};

FormButton.defaultProps = {
	enableSupportSpacing: true
};

export default FormButton;
