import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// CONSTANTS
import FinancialGoalOptions from 'components/Dropdown/FinancialGoalsOptions.json';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function FinancialGoalDropdown({
	dropDownSX,
	dropDownLabelSX,
	financialGoal,
	setFinancialGoal,
	sx,
	withoutCashout
}) {
	const financialGoalOptions = useMemo(() => {
		if (withoutCashout) return FinancialGoalOptions.slice(0, 3);
		return FinancialGoalOptions;
	}, [withoutCashout]);

	return (
		<Grid
			item
			xs={12}
			sm={11}
			md={5}
			lg={4}
			sx={{ marginLeft: 1, marginRight: 1, ...sx }}
		>
			<Dropdown
				items={financialGoalOptions}
				label="Financial Goal"
				labelSx={dropDownLabelSX}
				onChange={setFinancialGoal}
				selected={financialGoal}
				sx={dropDownSX}
				variant="outlined"
			/>
		</Grid>
	);
}

FinancialGoalDropdown.propTypes = {
	dropDownSX: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	financialGoal: PropTypes.string.isRequired,
	gridColumns: PropTypes.number,
	setFinancialGoal: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx,
	withoutCashout: PropTypes.bool
};

FinancialGoalDropdown.defaultProps = {
	dropDownLabelSX: {
		whiteSpace: 'nowrap'
	},
	dropDownSX: {},
	gridColumns: 5,
	sx: {
		marginBottom: 0
	},
	withoutCashout: false
};

export default FinancialGoalDropdown;
