import PropTypes from 'prop-types';

// Our Components
import ExpectedPayoffDate from 'components/Forms/Liabilities/Common/ExpectedPayoffDate';
import LiabilityFormItem from 'components/Forms/Liabilities/Common/LiabilityFormItem';
import LoanTypeDropdown from 'components/Dropdown/LoanTypeDropdown';
import MonthlyPaymentInput from 'components/Input/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Input/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import TenureDropdown from 'components/Dropdown/TenureDropdown';
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

function FinancialLiabilityForm({
	outstandingBalance,
	setOutstandingBalance,
	monthlyPayment,
	setMonthlyPayment,
	rate,
	setRate,
	approxPayoffDate,
	handleApproxPayoffDateChange,
	ownershipTenure,
	setOwnershipTenure,
	loanType,
	setLoanType,
	lender,
	setLender
}) {
	return (
		<>
			<LiabilityFormItem>
				<OutstandingBalanceInput
					outstandingBalance={outstandingBalance}
					setOutstandingBalance={setOutstandingBalance}
				/>
			</LiabilityFormItem>

			<LiabilityFormItem>
				<MonthlyPaymentInput
					monthlyPayment={monthlyPayment}
					outstandingBalance={outstandingBalance}
					setMonthlyPayment={setMonthlyPayment}
				/>
			</LiabilityFormItem>

			<LiabilityFormItem>
				<RateInput rate={rate} setRate={setRate} />
			</LiabilityFormItem>

			<ExpectedPayoffDate
				approxPayoffDate={approxPayoffDate}
				handleApproxPayoffDateChange={handleApproxPayoffDateChange}
			/>

			{/* Takes 5 Grid Columns */}
			<TenureDropdown
				tenure={ownershipTenure}
				setTenure={setOwnershipTenure}
				dropDownSX={{ width: '100%' }}
				sx={{ marginBottom: 0 }}
			/>

			{/* Takes 5 Grid Columns */}
			<LoanTypeDropdown loanType={loanType} setLoanType={setLoanType} />

			<LiabilityFormItem>
				<TextInput
					type={TEXT}
					label="Lender"
					subLabel="Enter the name of your lender"
					value={lender}
					onChange={setLender}
				/>
			</LiabilityFormItem>
		</>
	);
}

FinancialLiabilityForm.propTypes = {
	outstandingBalance: PropTypes.number.isRequired,
	setOutstandingBalance: PropTypes.func.isRequired,
	monthlyPayment: PropTypes.number.isRequired,
	setMonthlyPayment: PropTypes.func.isRequired,
	rate: PropTypes.number.isRequired,
	setRate: PropTypes.func.isRequired,
	approxPayoffDate: PropTypes.string.isRequired,
	setApproxPayoffDate: PropTypes.func.isRequired,
	handleApproxPayoffDateChange: PropTypes.func.isRequired,
	ownershipTenure: PropTypes.string.isRequired,
	setOwnershipTenure: PropTypes.func.isRequired,
	loanType: PropTypes.string.isRequired,
	setLoanType: PropTypes.func.isRequired,
	lender: PropTypes.string.isRequired,
	setLender: PropTypes.func.isRequired
};

export default FinancialLiabilityForm;
