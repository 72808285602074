import { Grid } from '@mui/material';

export default function LiabilityFormContainer({ children }) {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                marginLeft: { sm: 10, xs: 0 }
            }}
        >
            {children}
        </Grid>
    );
} 