import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Dropdown/FinancialGoalDropdown';
import FinancialLiabilityForm from 'components/Forms/Liabilities/Common/FinancialLiabilityForm';
import FormButton from 'components/Forms/FormButton';
import LiabilityFormHeading from 'components/Forms/Liabilities/LiabilityFormHeading';
import LiabilityFormContainer from 'components/Forms/Liabilities/Common/LiabilityFormContainer';
import LiabilityFormItem from 'components/Forms/Liabilities/Common/LiabilityFormItem';
import Loader from 'components/Loader';
import MileageTextInput from 'components/Input/MileageTextInput';
import TextInput from 'components/Input/TextInput';
import USStateDropdown from 'components/Dropdown/USStateDropdown';

// Input Types
import { TEXT } from 'components/Input/Types';

// Our hooks
import useGetUserAutoLiability from 'hooks/client/liabilities/queries/useGetUserAutoLiability';
import useSaveAutoLiability from 'hooks/client/liabilities/mutations/useMutateSaveAutoLiability';

// Utils
import {
	convertMonthsToYears,
	dataIsValid,
	dateToIsoFormat,
	FINANCIAL_GOAL_TO_ENUM_MAPPING,
	getDefaultApproxPayoffDate,
	EMUM_TO_FINANCIAL_GOAL_MAPPING as inverseFinancialGoalMapping,
	isSubmissionReady,
	normalizeLoanType,
	roundToDecimal
} from 'shared/utils';

// CONSTANTS
import { AUTO } from 'shared/constants';

const VEHICLE_CONDITION_OPTIONS = ['Excellent', 'Good', 'Fair', 'Poor']; // This is for the DatePicker of Graduation Year
const KEYS_TO_IGNORE = new Set(['make', 'model', 'vehicleId', 'color', 'year']);

function AutoLiability() {
	const navigate = useNavigate();
	const saveUserAutoLiability = useSaveAutoLiability();

	const { isLoading, data, isSuccess } = useGetUserAutoLiability();

	const { isLoading: isMutationLoading } = saveUserAutoLiability;

	// Auto Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(AUTO)
	);
	const [lender, setLender] = useState('');
	const [loanType, setLoanType] = useState('Fixed');
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Vehicle Related Data
	const [licensePlate, setLicensePlate] = useState('');
	const [usState, setUSState] = useState('');
	const [vehicleCondition, setVehicleCondition] = useState('');
	const [vehicleMileage, setVehicleMileage] = useState('');
	const [vehicleVINNumber, setVehicleVINNumber] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	useEffect(() => {
		if (isSuccess) {
			// This maps the fieldName in the data response to the corresponding state fnc that updates that state
			const WANTED_KEY_SET_MAPPING = {
				expectedPayOffDate: setApproxPayoffDate,
				condition: setVehicleCondition,
				financialGoal: setFinancialGoal,
				licensePlate: setLicensePlate,
				interestRate: setRate,
				lender: setLender,
				loanType: setLoanType,
				mileage: setVehicleMileage,
				monthlyPay: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				state: setUSState,
				tenureMonth: setOwnershipTenure,
				vin: setVehicleVINNumber
			};

			const autoLiabilityFields = Object.keys(data);

			try {
				autoLiabilityFields.forEach((autoLiabilityField) => {
					const currentData = data[autoLiabilityField];
					const setUpdater =
						WANTED_KEY_SET_MAPPING[autoLiabilityField];

					if (autoLiabilityField === 'vehicleInfo') {
						// we gotta filter keys not used in UI
						const autoSpecificFields = Object.keys(
							currentData
						).filter(
							(currentKey) => !KEYS_TO_IGNORE.has(currentKey)
						);

						autoSpecificFields.forEach((autoSpecificField) => {
							const autoSpecificUpdater =
								WANTED_KEY_SET_MAPPING[autoSpecificField];
							const autoSpecificData =
								currentData[autoSpecificField];
							// This data is may or may not be null
							if (!dataIsValid(autoSpecificData)) return;
							if (autoSpecificField === 'mileage') {
								autoSpecificUpdater(`${autoSpecificData}`);
								return;
							}
							autoSpecificUpdater(autoSpecificData);
						});

						return;
					}

					if (
						autoLiabilityField === 'monthlyPay' ||
						autoLiabilityField === 'outstandingBalance' ||
						autoLiabilityField === 'interestRate'
					) {
						// Here the values are ints we need to convert them to strings
						setUpdater(`${currentData}`);
						return;
					}

					if (autoLiabilityField === 'tenureMonth') {
						// here the backend provides in months e.g. 360 but we want in years
						// hence 360 / 12 => 30 years
						const formattedTenureMonth = `${convertMonthsToYears(
							currentData,
							0
						)}`;
						setUpdater(formattedTenureMonth);
						return;
					}

					if (autoLiabilityField === 'loanType') {
						const formattedLoanType =
							normalizeLoanType(currentData);
						setUpdater(formattedLoanType);
						return;
					}

					if (autoLiabilityField === 'financialGoal') {
						setUpdater(inverseFinancialGoalMapping(currentData));
						return;
					}
					setUpdater(currentData);
				});
			} catch (e) {
				console.error(e);
			}
		}
	}, [data]);

	const formValues = [
		approxPayoffDate,
		financialGoal,
		lender,
		monthlyPayment,
		outstandingBalance,
		ownershipTenure,
		loanType,
		rate,
		vehicleCondition,
		vehicleMileage
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && approxPayoffDate !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);
		const formattedLoanType = loanType.toUpperCase();
		const formattedRate = roundToDecimal(+rate, 2);

		const autoData = {
			approxPayoffDate: formattedApproxPayOffDate,
			condition: vehicleCondition,
			financialGoal: formattedFinancialGoal,
			lender,
			licensePlate,
			loanType: formattedLoanType,
			mileage: vehicleMileage,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			rate: formattedRate,
			tradelineType: 'AUTO', // can be one of AUTO | MORTGAGE | PERSONAL | STUDENT
			tenure: +ownershipTenure,
			state: usState,
			vin: vehicleVINNumber
		};

		saveUserAutoLiability.mutate(autoData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Auto Loan</title>
				</Helmet>
				<LiabilityFormHeading headingText="Auto Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);

	return (
		<>
			<Helmet>
				<title>Auto Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Auto Loan" />

			<LiabilityFormContainer>
				<FinancialLiabilityForm
					outstandingBalance={outstandingBalance}
					setOutstandingBalance={setOutstandingBalance}
					monthlyPayment={monthlyPayment}
					setMonthlyPayment={setMonthlyPayment}
					rate={rate}
					setRate={setRate}
					approxPayoffDate={approxPayoffDate}
					handleApproxPayoffDateChange={handleApproxPayoffDateChange}
					ownershipTenure={ownershipTenure}
					setOwnershipTenure={setOwnershipTenure}
					loanType={loanType}
					setLoanType={setLoanType}
					lender={lender}
					setLender={setLender}
				/>

				<LiabilityFormItem>
					<TextInput
						type={TEXT}
						label="Vehicle VIN number"
						subLabel="Optional"
						value={vehicleVINNumber}
						onChange={setVehicleVINNumber}
					/>
				</LiabilityFormItem>

				<LiabilityFormItem>
					<TextInput
						type={TEXT}
						label="License Plate number"
						subLabel="Optional"
						value={licensePlate}
						onChange={setLicensePlate}
					/>
				</LiabilityFormItem>

				<USStateDropdown
					state={usState}
					setState={setUSState}
					helperText="Optional"
				/>

				<LiabilityFormItem>
					<MileageTextInput
						vehicleMileage={vehicleMileage}
						setVehicleMileage={setVehicleMileage}
					/>
				</LiabilityFormItem>

				<LiabilityFormItem>
					<Dropdown
						dataTestTag="condition"
						items={VEHICLE_CONDITION_OPTIONS}
						selected={vehicleCondition}
						onChange={setVehicleCondition}
						variant="outlined"
						label="What condition is your vehicle in?"
					/>
				</LiabilityFormItem>

				<FinancialGoalDropdown
					withoutCashout
					financialGoal={financialGoal}
					setFinancialGoal={setFinancialGoal}
				/>

				<FormButton
					isFormReady={isFormReady}
					isMutationLoading={isMutationLoading}
					submitLiabilityData={submitLiabilityData}
				/>
			</LiabilityFormContainer>
		</>
	);
}

export default AutoLiability;
