import { Grid } from '@mui/material';

function LiabilityFormItem({ children }) {
	return (
		<Grid
			item
			xs={12}
			sm={11}
			md={5}
			lg={4}
			sx={{ marginLeft: 1, marginRight: 1 }}
		>
			{children}
		</Grid>
	);
}

export default LiabilityFormItem;
